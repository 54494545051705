import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, TextField } from "@mui/material";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Space, Table, Tag, Button, Select } from "antd";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from "multiselect-react-dropdown";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CsvHeadersModal from "./CsvHeadersModal";
import qs from "qs";
import Status from "./Status";
import { Link, useNavigate } from "react-router-dom";

const getRandomuserParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const Leads = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [total, setTotal] = useState(0);
  const [templatedata, setTemplateData] = useState([]);
  const [editContactId, setEditContactId] = useState(null);
  const navigate = useNavigate();
  // Add Custom field
  const [customsData, setCustomsData] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [nextCustomFieldType, setNextCustomFieldType] = useState("");
  // Edit all
  const [editAllValue, setEditAllValue] = useState([]);
  const [nextEditAll, setNextEditAll] = useState("");
  // Edit Filtered
  const [editFilteredValue, setEditFilteredValue] = useState([]);
  const [nextEditFiltered, setNextEditFiltered] = useState("");
  // Edit selected
  const [isEditingSelected, setIsEditingSelected] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [editSelectedValue, setEditSelectedValue] = useState([]);
  const [nextEditSelected, setNextEditSelected] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [messageTemplate, setMessageTemplate] = useState("");

  const [contact, setContact] = useState({
    name: "",
    email: "",
    whatsappMobile: "",
    mobile: "",
    personalisedattachment: "",
    tag: "",
  });

  const [useSameNumber, setUseSameNumber] = useState(false);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleEditContact = (_id) => {
    let selectedContact;

    // Check if the contact exists in searchedData
    if (searchedData.length > 0) {
      selectedContact = searchedData.find((contact) => contact._id === _id);
    } else {
      // If not found in searchedData, fall back to data
      selectedContact = data.find((contact) => contact._id === _id);
    }

    if (selectedContact) {
      setEditContactId(_id); // Set the ID of the contact being edited
      setContact({
        name: selectedContact.name,
        email: selectedContact.email,
        whatsappMobile: selectedContact.whatsappMobile,
        mobile: selectedContact.mobile,
        personalisedattachment: selectedContact.personalisedattachment,
      }); // Populate the form fields with the selected contact data
      setSelectedTags(selectedContact.tag);
      setCustomFields(
        selectedContact.customFields.map((field) => ({
          title: field.title,
          value: field.value,
        }))
      );
    }

    // console.log(`Editing contact with ID: ${_id}`);
  };

  const fetchCustomsData = async () => {
    try {
      const response = await axios.get("/api/contact/customfield");
      setCustomsData(response.data);
    } catch (error) {
      console.error("Error fetching customs data:", error);
    }
  };

  const fetchContactData = async () => {
    try {
      const params = {
        startreq:
          (tableParams.pagination.current - 1) *
          tableParams.pagination.pageSize,
        limit: tableParams.pagination.pageSize,
      };

      const response = await axios.get(
        `/api/contact/getleads?${qs.stringify(params)}`
      );
      setData(response.data.contacts);
      setTotal(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };

  const fetchSearchData = async () => {
    if (searchQuery.trim() !== "") {
      setLoading(true);
      try {
        const response = await axios.get("/api/contact/search", {
          params: { query: searchQuery },
        });
        setSearchedData(response.data.contacts);
        // console.log(response.data.contacts);
      } catch (error) {
        console.error("Error fetching search data:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchedData([]);
    }
  };

  useEffect(() => {
    fetchSearchData();
  }, [searchQuery]);

  const fetchTemplateData = async () => {
    try {
      const response = await axios.get("/api/template/getalltemplate");
      setTemplateData(response.data);
    } catch (error) {
      console.error("Error fetching customs data:", error);
    }
  };

  // Fetch Contact data on component mount
  useEffect(() => {
    fetchContactData();
    fetchTemplateData();
    fetchCustomsData();
  }, [JSON.stringify(tableParams)]);

  const handleChangeContact = (e) => {
    const { name, value } = e.target;
    setContact((prevContact) => {
      const updatedContact = {
        ...prevContact,
        [name]: value,
      };

      if (useSameNumber && name === "mobile") {
        updatedContact.whatsappMobile = value;
      }
      return updatedContact;
    });
  };

  const handleCheckboxChange = () => {
    setUseSameNumber((prevValue) => !prevValue);
  };

  const handleChangeTags = (selectedTags) => {
    setSelectedTags(selectedTags);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleSubmitLeads = async (e) => {
    e.preventDefault();
    // Check for errors before submitting
    try {
      setLoading(true);
      const formData = {
        ...contact,
        flag: "leads",
        customFields: customFields.filter((field) => field.value.trim() !== ""),
        tag: selectedTags,
      };

      // Check if the checkbox is checked
      if (useSameNumber) {
        // Use the WhatsApp number for mobile if the checkbox is checked
        formData.mobile = formData.whatsappMobile;
      }

      if (editContactId) {
        await axios
          .put(`/api/contact/update/${editContactId}`, formData)
          .then((res) => {
            setContact({
              name: "",
              email: "",
              whatsappMobile: "",
              mobile: "",
              personalisedattachment: "",
            });
            setCustomFields([]);
            setSelectedTags([]);
            Swal.fire({
              title: "Contact Updated successfully!",
              icon: "success",
              // showCancelButton: false,
              // confirmButtonColor: "#3085d6",
              // confirmButtonText: "Saved",
              showConfirmButton: false, // No "Saved" button
              timer: 2000,
            }).then(() => {
              // Fetch the updated template data
              fetchContactData();
              fetchSearchData();
            });
          })
          .catch((error) => {
            let errorMessage = "Error creating Contact. Please try again.";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMessage = error.response.data.message;
            }
            Swal.fire("Error", errorMessage, "error");
            console.log(error);
          });
      } else {
        await axios
          .post("/api/contact/add", formData)
          .then((response) => {
            setContact({
              name: "",
              email: "",
              whatsappMobile: "",
              mobile: "",
              personalisedattachment: "",
              tag: "",
              customFields: "",
            });
            setCustomFields([]);
            setSelectedTags([]);
            Swal.fire({
              title: "Contact Added successfully!",
              icon: "success",
              // showCancelButton: false,
              // confirmButtonColor: "#3085d6",
              // confirmButtonText: "Saved",
              showConfirmButton: false, // No "Saved" button
              timer: 1000,
            }).then(() => {
              // Fetch the updated template data
              fetchContactData();
            });
          })
          .catch((error) => {
            let errorMessage = "Error creating Contact. Please try again.";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMessage = error.response.data.message;
            }
            Swal.fire("Error", errorMessage, "error");
            Swal.fire("Error", errorMessage, "error");
            console.log(error);
          });
      }
    } catch (error) {
      console.error("Error submitting contact data:", error);
    } finally {
      // Ensure loading state is cleared even in case of error
      setLoading(false);
    }
  };

  const handleSubmitTags = async (e) => {
    e.preventDefault();
    // Check for errors before submitting
    try {
      setLoading(true);

      // Ensure selectedTags is an array of objects with title property
      const formData = {
        tags: selectedTags.map((tag) => ({ title: tag })),
      };

      await axios
        .post("/api/contact/updatealltags", formData)
        .then((response) => {
          setContact({
            tag: "",
          });
          setCustomFields([]);
          setSelectedTags([]);
          Swal.fire({
            title: "Tags Updated successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          }).then(() => {
            // Fetch the updated template data
            fetchContactData();
            fetchSearchData();
          });
        })
        .catch((error) => {
          Swal.fire("Error", "Error updating tags. Please try again.", "error");
        });
    } catch (error) {
      console.error("Error submitting contact data:", error);
    } finally {
      // Ensure loading state is cleared even in case of error
      setLoading(false);
    }
  };

  useEffect(() => {
    // Clear loading state when component unmounts
    return () => {
      setLoading(false);
    };
  }, []);

  // Add Custom Fields Starts

  const handleAddCustomField = () => {
    const selectedCustom = customsData.find(
      (custom) => custom.title === nextCustomFieldType
    );

    if (selectedCustom) {
      setCustomFields([
        ...customFields,
        { title: selectedCustom.title, value: "" },
      ]);
      setNextCustomFieldType(""); // Reset the next custom field type
    }
  };

  const handleCustomFieldValueChange = (index, event) => {
    const updatedFields = [...customFields];
    updatedFields[index].value = event.target.value;
    setCustomFields(updatedFields);
  };

  const handleRemoveCustomField = (index) => {
    const updatedFields = [...customFields];
    updatedFields.splice(index, 1);
    setCustomFields(updatedFields);
  };

  // Add Custom Fields Ends

  // Edit All Starts
  const handleAddEditAll = () => {
    const selectedEditAll = customsData.find(
      (custom) => custom.title === nextEditAll
    );

    if (selectedEditAll) {
      setEditAllValue([
        ...editAllValue,
        { title: selectedEditAll.title, value: "" },
      ]);
      setNextEditAll(""); // Reset the next custom field type
    }
  };

  const handleEditAllValueChange = (index, event) => {
    const updatedEditFields = [...editAllValue];
    updatedEditFields[index].value = event.target.value;
    setEditAllValue(updatedEditFields);
  };

  const handleRemoveEditAll = (index) => {
    const updatedEditFields = [...editAllValue];
    updatedEditFields.splice(index, 1);
    setEditAllValue(updatedEditFields);
  };
  // Edit All Ends

  // Edit Filtered Starts
  const handleAddEditFiltered = () => {
    const selectedEditFiltered = customsData.find(
      (custom) => custom.title === nextEditFiltered
    );

    if (selectedEditFiltered) {
      setEditFilteredValue([
        ...editFilteredValue,
        { title: selectedEditFiltered.title, value: "" },
      ]);
      setNextEditFiltered(""); // Reset the next custom field type
    }
  };

  const handleEditFilteredValueChange = (index, event) => {
    const updatedEditFields = [...editFilteredValue];
    updatedEditFields[index].value = event.target.value;
    setEditFilteredValue(updatedEditFields);
  };

  const handleRemoveEditFiltered = (index) => {
    const updatedEditFields = [...editFilteredValue];
    updatedEditFields.splice(index, 1);
    setEditFilteredValue(updatedEditFields);
  };
  // Edit Filtered Ends

  // Edit Selected Starts
  const handleAddEditSelected = () => {
    const selectedEditSelected = customsData.find(
      (custom) => custom.title === nextEditSelected
    );

    if (selectedEditSelected) {
      setEditSelectedValue([
        ...editSelectedValue,
        { title: selectedEditSelected.title, value: "" },
      ]);
      setNextEditSelected(""); // Reset the next custom field type
    }
  };

  const handleEditSelectedValueChange = (index, event) => {
    const updatedEditSelectedFields = [...editSelectedValue];
    updatedEditSelectedFields[index].value = event.target.value;
    setEditSelectedValue(updatedEditSelectedFields);
  };

  const handleRemoveEditSelected = (index) => {
    const updatedEditSelectedFields = [...editSelectedValue];
    updatedEditSelectedFields.splice(index, 1);
    setEditSelectedValue(updatedEditSelectedFields);
  };

  const handleDeleteContact = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // Delete contact with the given ID
      axios
        .delete(`/api/contact/delete/${_id}`)
        .then((res) => {
          fetchContactData();
          Swal.fire("Deleted!", "Contact deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const handleDeleteSelected = () => {
    deleteSelectedItems();
    setSelectedRowKeys([]); // Clear the selected keys
  };

  const deleteSelectedItems = async () => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const response = await axios.delete(
          "/api/contact/deleteselecteditems",
          {
            data: { ids: selectedRowKeys }, // Pass selected IDs in the request body
          }
        );

        if (response.status === 200) {
          // console.log("Items deleted successfully");
          // fetchData();
          fetchContactData();
          Swal.fire("Deleted!", "Contact deleted successfully!", "success");
        } else {
          console.error("Failed to delete items");
          Swal.fire("Error", "Something went wrong!", "error");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // const filteredCustomFields = customsData.filter(
  //   (custom) => custom.showintable === true
  // );

  // const customFieldColumn = filteredCustomFields.map((custom) => ({
  //   title: custom.title,
  //   dataIndex: custom.title, // Assuming title is a unique identifier for each custom field
  //   key: custom.title,
  // }));

  function generateTagColor(tag) {
    const hash = tag
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    const hue = hash % 360; // Use the hash value to determine the hue
    return `hsl(${hue}, 70%, 50%)`; // Generate an HSL color based on the hue
  }

  const [selectedContactId, setSelectedContactId] = useState(null);

  const handleNameClick = (event, contactId) => {
    event.preventDefault();
    setSelectedContactId(contactId);
  };

  useEffect(() => {
    if (selectedContactId) {
      navigate(`/dashboard/status/${selectedContactId}/timeline`); // Example navigation
    }
  }, [selectedContactId, navigate]);

  // ######################################### Flag Update ###########################################

  const [flagOptions, setFlagOptions] = useState("");

  // Function to handle flag option change for a specific record
  const handleFlagOptionChange = (e, _id) => {
    const selectedOption = e.target.value;
    setFlagOptions((prevOptions) => ({
      ...prevOptions,
      [_id]: selectedOption,
    }));
  };

  // Function to update flag in the backend using Axios
  const updateFlagInBackend = async (_id, selectedOption) => {
    try {
      const response = await axios.put(`api/contact/flag/${_id}`, {
        flag: selectedOption,
      });

      if (response.status !== 200) {
        throw new Error("Failed to update flag");
      }

      // Optionally handle success feedback
      // console.log("Flag updated successfully");
      fetchContactData();
    } catch (error) {
      console.error("Error updating flag:", error);
      // Optionally handle error feedback
      alert("Failed to update flag. Please try again.");
    }
  };

  // useEffect to trigger backend update when flagOption changes for any record
  useEffect(() => {
    // Loop through each record's flag option and update backend
    Object.entries(flagOptions).forEach(([id, option]) => {
      updateFlagInBackend(id, option);
    });
  }, [flagOptions]);

  const columns = [
    //Actions
    {
      title: "Action",
      fixed: "left",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            className="edit-btn"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            onClick={() => handleEditContact(record._id)}
          >
            <i className="ri-eye-line"></i>
          </button>

          <button
            onClick={() => handleDeleteContact(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
    //Name
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (text, record) => (
        <a
          href="#"
          className="namelink"
          onClick={(e) => handleNameClick(e, record._id)}
        >
          {text}
        </a>
      ),
    },

    // ...customFieldColumn,
    //Email
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },
    //Whatsapp Number
    {
      title: "WhatsApp Number",
      dataIndex: "whatsappMobile",
      key: "whatsappMobile",
      ellipsis: true,
    },
    //Phone
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      ellipsis: true,
    },
    //Flag

    {
      title: "Flag",
      dataIndex: "flag",
      key: "flag",
      ellipsis: true,
      render: (text, record) => (
        <select
          className="form-select"
          value={flagOptions[record._id] || record.flag}
          onChange={(e) => handleFlagOptionChange(e, record._id)}
        >
          <option value="leads" disabled>
            Leads
          </option>
          <option value="customer">Customer</option>
          <option value="webinarleads">Webinar Leads</option>
        </select>
      ),
    },
    //Tags
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      ellipsis: true,

      render: (tags = []) => (
        <div>
          {tags.slice(0, 2).map((tag, index) => (
            <Tag
              key={index}
              style={{
                backgroundColor: generateTagColor(tag),
                color: "#fff",
                marginBottom: "8px",
              }}
            >
              {tag}
            </Tag>
          ))}
          {tags.length > 2 && <span> +{tags.length - 2} more</span>}
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
  };

  const hasSelected = selectedRowKeys.length > 0;

  const deleteButtonStyle = {
    display: hasSelected ? "block" : "none",
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getTagsData = async () => {
      try {
        const response = await axios.get("/api/contact/tags/gettags");
        const responseData = response.data;

        const getTagsName = responseData.map((tag) => tag.title);

        setOptions(getTagsName);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getTagsData();
  }, []);

  const handlesendMessages = async (event) => {
    event.preventDefault();
    const selectedContacts = selectedRowKeys.map(
      (key) => data.find((item) => item._id === key)?.whatsappMobile
    );

    const apiUrl = "/api/template/sendmessage";

    try {
      // Check if a message template is selected
      if (!messageTemplate) {
        // Handle case where no message template is selected
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please Select any one template to send.",
        });
        return;
      }

      const response = await axios.post(apiUrl, {
        receiverMobileNos: selectedContacts,
        message: messageTemplate,
      });

      // Check if the response status code indicates success (e.g., 200 OK)
      if (response.status === 200) {
        if (response.data.success) {
          // alert("All messages sent successfully");
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "All messages sent successfully.",
          });
          setSelectedRowKeys([]);
        } else {
          // alert("Some messages failed to send");
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Some messages failed to send.",
          });
        }
      } else {
        // alert("Failed to send messages");
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to send messages.",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // alert("Success Full");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to send messages.",
      });
    }
  };

  const handleAddContactButtonClick = () => {
    setEditContactId(null);

    setContact({
      name: "",
      email: "",
      whatsappMobile: "",
      mobile: "",
      personalisedattachment: "",
    });
    setCustomFields([]);
    setSelectedTags([]);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [showHeadersModal, setShowHeadersModal] = useState(false);

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // console.log(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        // console.log(content);
        // Parse the CSV content and extract headers
        const lines = content.split("\n");
        if (lines.length > 0) {
          const headers = lines[0].split(",");
          setCsvHeaders(headers);
          setShowHeadersModal(true);
          // console.log(`showHeadersModal is now ${showHeadersModal}`);
          // console.log(`showHeadersModal is now`);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleCloseModal = () => {
    setShowHeadersModal(false);
  };

  const [customTitle, setCustomTitle] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customFieldResponse = await axios.get("/api/contact/customfield");

        const extractedTitles = customFieldResponse.data.map(
          (custom) => custom.title
        );
        setCustomTitle(extractedTitles);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Delete All Functionality
  const handleDeleteAllContacts = async () => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "This action will delete all contacts. This cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete all!",
    });

    if (confirmed.isConfirmed) {
      try {
        const response = await axios.delete("/api/contact/deleteall");
        // console.log(response.data);
        if (response) {
          // Optionally, you can update the local state or perform any other actions.
          // console.log("All contacts deleted successfully.");
          Swal.fire(
            "Deleted!",
            "All Contacts deleted successfully!",
            "success"
          );
          fetchContactData();
        } else {
          console.error("Failed to delete all contacts.");
          Swal.fire("Error", "Failed to delete all contacts.", "error");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        Swal.fire(
          "Error",
          "An error occurred while deleting contacts.",
          "error"
        );
      }
    }
  };

  // Filter Functionality
  const [selectedField, setSelectedField] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [filtersActive, setFiltersActive] = useState(false);

  const applyFilter = () => {
    if (selectedField && selectedCondition) {
      const filtered = filteredContacts(data, selectedField, selectedCondition);
      setFilteredData(filtered);
      setSelectedField("");
      setSelectedCondition("");
      setFiltersActive(true);
      setIsFilterApplied(true);
    } else {
      // If no valid filter criteria are selected, display the original data
      setFilteredData([]);
      setSelectedCondition("");
      setSelectedField("");
      setFiltersActive(false);
      setIsFilterApplied(false);
    }
  };

  const filteredContacts = (contacts, field, condition) => {
    if (!field || !condition) {
      return contacts; // Return all contacts if no filter is selected
    }

    // Filter contacts based on the selected field and condition
    return contacts.filter((contact) => {
      // Assuming customFields is an array of objects with 'title' as the field name
      const fieldExists = contact.customFields.some((cf) => cf.title === field);

      if (condition === "exist") {
        return fieldExists;
      } else if (condition === "doesnotexist") {
        return !fieldExists;
      }

      return true; // Include contact by default if condition doesn't match 'exist' or 'doesnotexist'
    });
  };

  // console.log("SelectedRowsKeys Data:", selectedRowKeys);

  useEffect(() => {
    // console.log("Filtered Data:", filteredData);
    // console.log("Filtered Data");
  }, [filteredData]);

  // Edit All Contacts
  const applyEditAllChanges = async () => {
    const customFieldsToUpdate = editAllValue.map((field) => ({
      title: field.title,
      value: field.value,
    }));

    try {
      // Make the API request to update all contacts with the custom field values
      await axios
        .put("/api/contact/editall", {
          customFields: customFieldsToUpdate,
          filtersActive: filtersActive,
        })
        .then(() => {
          Swal.fire({
            title: "Edit All successfully!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Saved",
          });
        })
        .then(() => {
          fetchContactData();
        });
    } catch (error) {
      // Handle errors (you can add error handling logic here)
      console.error("Error updating custom fields:", error);
      Swal.fire(
        "Error",
        "Error updating All Contact. Please try again.",
        "error"
      );
    }
  };

  const applyEditSelectedChanges = async () => {
    try {
      const updatedRows = [];

      for (const key of selectedRowKeys) {
        const selectedContact = data.find((contact) => contact._id === key);

        const updatedCustomFields = editSelectedValue.map((field) => ({
          title: field.title,
          value: field.value,
        }));

        const updatedContact = {
          ...selectedContact,
          customFields: updatedCustomFields,
        };

        updatedRows.push(updatedContact);
      }

      // console.log(updatedRows);

      // Make the API request to update custom fields for selected contacts
      const response = await axios.put("/api/contact/selectededit", {
        updatedRows,
      });

      const { data: responseData } = response;
      // console.log(responseData);

      Swal.fire({
        title: "Edit Selected successfully!",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Saved",
      });

      // Fetch updated contact data after successful update
      fetchContactData();
    } catch (error) {
      console.error("Error updating custom fields:", error); // Log the error
      Swal.fire(
        "Error",
        "Failed to update selected contacts. Please try again later.",
        "error"
      );
    }
  };

  const applyEditFilteredChanges = async () => {
    try {
      const updatedFilteredRows = [];
      const filteredDataIds = filteredData.map((item) => item._id);

      // console.log(filteredDataIds);

      for (const key of filteredDataIds) {
        const selectedFilteredContact = data.find(
          (contact) => contact._id === key
        );

        const updatedCustomFields = editFilteredValue.map((field) => ({
          title: field.title,
          value: field.value,
        }));

        const updatedContact = {
          ...selectedFilteredContact,
          customFields: updatedCustomFields,
        };

        updatedFilteredRows.push(updatedContact);
      }

      // console.log(updatedFilteredRows);

      // Make the API request to update custom fields for selected contacts
      const response = await axios.put("/api/contact/filtercontact", {
        updatedFilteredRows,
      });

      const { data: responseData } = response;
      // console.log(responseData);

      Swal.fire({
        title: "Edit Filtered successfully!",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Saved",
      });

      // Fetch updated contact data after successful update
      fetchContactData();
    } catch (error) {
      console.error("Error updating Filtered custom fields:", error); // Log the error
      Swal.fire(
        "Error",
        "Failed to update Filtered contacts. Please try again later.",
        "error"
      );
    }
  };

  const handleRemoveFilter = () => {
    fetchContactData();
    setIsFilterApplied(false);
  };

  // Function to determine the data source based on filters and search
  const getDataSource = () => {
    if (filteredData.length > 0) {
      // Display filtered data if filters are applied
      return filteredData;
    } else if (searchQuery.trim() !== "") {
      // Display searched contacts if search query is present
      // return searchContacts();
      return searchedData;
    } else {
      // Display all contacts by default
      return data;
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  return (
    <div>
      <div className="page-content w-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 py-3">Leads </h4>
                <div>
                  <h4 className="mb-sm-0 py-3">Total Contacts : {total}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <div id="contactList" className="card">
                <div className="card-header py-3">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="d-flex flex-wrap flex-grow-1 gap-2">
                      <button
                        type="button"
                        className="btn btn-primary add-btn"
                        onClick={handleAddContactButtonClick}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> Add
                        Leads
                      </button>
                      <form
                        className="d-flex"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <input
                          className="form-control me-2"
                          type="search"
                          id="search"
                          placeholder="Search"
                          aria-label="Search"
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                      </form>
                      {/* Send Message to selected Contacts  starts */}
                      <Button
                        className="antd-green-button"
                        style={deleteButtonStyle}
                        size="large"
                        data-bs-toggle="modal"
                        data-bs-target="#sendModal"
                      >
                        Send Message
                      </Button>
                      {/* Delete all Button */}
                      <Button
                        type="primary"
                        danger
                        onClick={handleDeleteSelected}
                        style={deleteButtonStyle}
                        size="large"
                      >
                        Delete{" "}
                        <span style={{ marginLeft: 8 }}>
                          {hasSelected
                            ? `(Selected ${selectedRowKeys.length} items)`
                            : ""}
                        </span>
                      </Button>

                      {/* Remove Filter */}
                      {isFilterApplied && (
                        <Button
                          type="primary"
                          danger
                          // style={deleteButtonStyle}
                          onClick={handleRemoveFilter}
                          size="large"
                        >
                          Remove Filter
                        </Button>
                      )}

                      {/* Select Template Modal */}
                      <div
                        className="modal fade"
                        id="sendModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Send Messages
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <form>
                                <div>
                                  <select
                                    className="form-select"
                                    margin="normal"
                                    value={messageTemplate}
                                    onChange={(e) =>
                                      setMessageTemplate(e.target.value)
                                    }
                                  >
                                    <option value="">
                                      --Select Template--
                                    </option>
                                    {templatedata.map((custom) => (
                                      <option
                                        key={custom._id}
                                        value={custom.textItem}
                                      >
                                        {custom.templateName}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="submit"
                                    data-bs-dismiss="modal"
                                    className="btn btn-primary"
                                    onClick={handlesendMessages}
                                  >
                                    Send Now
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Send Message to selected Contacts ends */}

                      {/* Add Contacts Modal */}
                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Add Leads
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <form onSubmit={handleSubmitLeads}>
                                <div>
                                  <TextField
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    label="Enter Name"
                                    name="name"
                                    value={contact.name}
                                    onChange={handleChangeContact}
                                    margin="normal"
                                    type="text"
                                    required
                                    autoFocus
                                  />
                                </div>
                                <div>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    label="Enter Email"
                                    name="email"
                                    value={contact.email}
                                    onChange={handleChangeContact}
                                    margin="normal"
                                    type="email"
                                    required
                                  />
                                </div>
                                <Grid
                                  container
                                  spacing={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={6}>
                                    <div>
                                      <PhoneInput
                                        inputClass="phone-input-className w-100"
                                        // country={"in"}
                                        value={contact.whatsappMobile}
                                        onChange={(value, data, event) => {
                                          if (event && event.target) {
                                            handleChangeContact(event);
                                          }
                                        }}
                                        inputProps={{
                                          name: "whatsappMobile",
                                          required: true,
                                          placeholder: "Enter Whatsapp Number",
                                        }}
                                        dropdownClass="custom-dropdown-className"
                                        required
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div>
                                      <PhoneInput
                                        inputClass="phone-input-className w-100"
                                        // country={"in"} // Set the default country or leave it empty
                                        // value={contact.mobile}
                                        value={
                                          useSameNumber
                                            ? contact.whatsappMobile
                                            : contact.mobile
                                        }
                                        onChange={(value, data, event) => {
                                          if (event && event.target) {
                                            handleChangeContact(event);
                                          }
                                        }}
                                        inputProps={{
                                          name: "mobile",
                                          placeholder: "Enter Mobile Number",
                                          required: true,
                                        }}
                                        dropdownClass="custom-dropdown-className"
                                        required
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    <label
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={useSameNumber}
                                        onChange={handleCheckboxChange}
                                      />
                                      <span>Use the same number for Calls</span>
                                    </label>
                                  </Grid>
                                </Grid>

                                <div>
                                  <TextField
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    label="Personalised Attachement (Optional)"
                                    name="personalisedattachment"
                                    value={contact.personalisedattachment}
                                    onChange={handleChangeContact}
                                    autoComplete="personalisedattachment"
                                    autoFocus
                                  />
                                </div>
                                <div>
                                  <Multiselect
                                    isObject={false}
                                    placeholder="Tags"
                                    onSelect={(selectedList) =>
                                      handleChangeTags(selectedList)
                                    }
                                    onRemove={(selectedList) =>
                                      handleChangeTags(selectedList)
                                    }
                                    options={options}
                                    selectedValues={selectedTags} // Pass selected tags to the Multiselect component
                                    showCheckbox
                                  />
                                </div>
                                <div>
                                  {customFields.map((field, index) => (
                                    <div key={index}>
                                      <Grid
                                        container
                                        spacing={1}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Grid item xs={11}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            label={`${field.title}`}
                                            type="text"
                                            margin="normal"
                                            value={field.value}
                                            onChange={(e) =>
                                              handleCustomFieldValueChange(
                                                index,
                                                e
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <MinusCircleOutlined
                                            style={{
                                              fontSize: "25px",
                                              textAlign: "center",
                                            }}
                                            onClick={() =>
                                              handleRemoveCustomField(index)
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}

                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "10px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Grid item xs={9}>
                                      <select
                                        className="form-select"
                                        margin="normal"
                                        value={nextCustomFieldType}
                                        onChange={(e) =>
                                          setNextCustomFieldType(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select a Custom Field
                                        </option>
                                        {customsData.map((custom) => (
                                          <option
                                            key={custom._id}
                                            value={custom.title}
                                          >
                                            {custom.title}
                                          </option>
                                        ))}
                                      </select>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Button
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        type="dashed"
                                        onClick={handleAddCustomField}
                                        icon={<PlusOutlined />}
                                      >
                                        Add field
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </div>

                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    data-bs-dismiss="modal"
                                    className="btn btn-primary"
                                  >
                                    Add Leads
                                  </button>
                                </div>
                              </form>
                            </div>
                            {loading && (
                              <div className="loading-indicator">
                                Loading...
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* Add Contacts Modal */}
                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Add Leads
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <form onSubmit={handleSubmitLeads}>
                                <div>
                                  <TextField
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    label="Enter Name"
                                    name="name"
                                    value={contact.name}
                                    onChange={handleChangeContact}
                                    margin="normal"
                                    type="text"
                                    required
                                    autoFocus
                                  />
                                </div>
                                <div>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    label="Enter Email"
                                    name="email"
                                    value={contact.email}
                                    onChange={handleChangeContact}
                                    margin="normal"
                                    type="email"
                                    required
                                  />
                                </div>
                                <Grid
                                  container
                                  spacing={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Grid item xs={6}>
                                    <div>
                                      <PhoneInput
                                        inputClass="phone-input-className w-100"
                                        // country={"in"}
                                        value={contact.whatsappMobile}
                                        onChange={(value, data, event) => {
                                          if (event && event.target) {
                                            handleChangeContact(event);
                                          }
                                        }}
                                        inputProps={{
                                          name: "whatsappMobile",
                                          required: true,
                                          placeholder: "Enter Whatsapp Number",
                                        }}
                                        dropdownClass="custom-dropdown-className"
                                        required
                                      />
                                    </div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <div>
                                      <PhoneInput
                                        inputClass="phone-input-className w-100"
                                        // country={"in"} // Set the default country or leave it empty
                                        // value={contact.mobile}
                                        value={
                                          useSameNumber
                                            ? contact.whatsappMobile
                                            : contact.mobile
                                        }
                                        onChange={(value, data, event) => {
                                          if (event && event.target) {
                                            handleChangeContact(event);
                                          }
                                        }}
                                        inputProps={{
                                          name: "mobile",
                                          placeholder: "Enter Mobile Number",
                                          required: true,
                                        }}
                                        dropdownClass="custom-dropdown-className"
                                        required
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: "left" }}
                                  >
                                    <label
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={useSameNumber}
                                        onChange={handleCheckboxChange}
                                      />
                                      <span>Use the same number for Calls</span>
                                    </label>
                                  </Grid>
                                </Grid>

                                <div>
                                  <TextField
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    label="Personalised Attachement (Optional)"
                                    name="personalisedattachment"
                                    value={contact.personalisedattachment}
                                    onChange={handleChangeContact}
                                    autoComplete="personalisedattachment"
                                    autoFocus
                                  />
                                </div>
                                <div>
                                  <Multiselect
                                    isObject={false}
                                    placeholder="Tags"
                                    onSelect={(selectedList) =>
                                      handleChangeTags(selectedList)
                                    }
                                    onRemove={(selectedList) =>
                                      handleChangeTags(selectedList)
                                    }
                                    options={options}
                                    selectedValues={selectedTags} // Pass selected tags to the Multiselect component
                                    showCheckbox
                                  />
                                </div>
                                <div>
                                  {customFields.map((field, index) => (
                                    <div key={index}>
                                      <Grid
                                        container
                                        spacing={1}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Grid item xs={11}>
                                          <TextField
                                            size="small"
                                            fullWidth
                                            label={`${field.title}`}
                                            type="text"
                                            margin="normal"
                                            value={field.value}
                                            onChange={(e) =>
                                              handleCustomFieldValueChange(
                                                index,
                                                e
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <MinusCircleOutlined
                                            style={{
                                              fontSize: "25px",
                                              textAlign: "center",
                                            }}
                                            onClick={() =>
                                              handleRemoveCustomField(index)
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}

                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "10px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Grid item xs={9}>
                                      <select
                                        className="form-select"
                                        margin="normal"
                                        value={nextCustomFieldType}
                                        onChange={(e) =>
                                          setNextCustomFieldType(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select a Custom Field
                                        </option>
                                        {customsData.map((custom) => (
                                          <option
                                            key={custom._id}
                                            value={custom.title}
                                          >
                                            {custom.title}
                                          </option>
                                        ))}
                                      </select>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Button
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        type="dashed"
                                        onClick={handleAddCustomField}
                                        icon={<PlusOutlined />}
                                      >
                                        Add field
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </div>

                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    data-bs-dismiss="modal"
                                    className="btn btn-primary"
                                  >
                                    Add Leads
                                  </button>
                                </div>
                              </form>
                            </div>
                            {loading && (
                              <div className="loading-indicator">
                                Loading...
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* ************************************************************* */}
                      {/* Update Tags Modal */}
                      <div
                        className="modal fade"
                        id="updateModal"
                        tabIndex="-1"
                        aria-labelledby="updateModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="updateModalLabel">
                                Update Tags
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <form onSubmit={handleSubmitTags}>
                                <div>
                                  <Multiselect
                                    isObject={false}
                                    placeholder="Tags"
                                    onSelect={(selectedList) =>
                                      handleChangeTags(selectedList)
                                    }
                                    onRemove={(selectedList) =>
                                      handleChangeTags(selectedList)
                                    }
                                    options={options}
                                    selectedValues={selectedTags} // Pass selected tags to the Multiselect component
                                    showCheckbox
                                  />
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    data-bs-dismiss="modal"
                                    className="btn btn-primary"
                                  >
                                    Update Tags
                                  </button>
                                </div>
                              </form>
                            </div>
                            {loading && (
                              <div className="loading-indicator">
                                Loading...
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* ************************************************************* */}
                    </div>
                    {/* Filter Button */}
                    <button
                      type="button"
                      className="btn btn-danger add-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#myLeftModal"
                    >
                      <i className="ri-filter-2-line me-1 align-bottom"></i>
                      Filter
                    </button>
                    <div className="import-button">
                      <label className="btn btn-success add-btn">
                        <i className="ri-add-fill me-1 align-bottom"></i> Import
                        CSV
                        <input
                          type="file"
                          accept=".csv"
                          style={{ display: "none" }}
                          onChange={handleFileSelection}
                        />
                      </label>
                      {showHeadersModal && (
                        <CsvHeadersModal
                          file={selectedFile}
                          csvHeaders={csvHeaders}
                          data={data}
                          onClose={handleCloseModal}
                          fetchContactData={fetchContactData()}
                        />
                      )}
                    </div>
                    <div>
                      <button
                        className="btn btn-outline-info"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => {
                          if (hasSelected) {
                            setIsEditingSelected(true);
                            setSelectedContactIds(selectedRowKeys);
                          } else {
                            setIsEditingSelected(false);
                          }
                        }}
                      >
                        <i className="ri-more-2-fill"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            href="#"
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#myEditModal"
                          >
                            {/* {isEditingSelected ? "Edit Selected" : "Edit All"} */}
                            {isEditingSelected
                              ? "Edit Selected"
                              : filtersActive
                              ? "Edit Filtered"
                              : "Edit All"}
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#updateModal"
                          >
                            {isEditingSelected
                              ? "Update Tags Selected"
                              : filtersActive
                              ? "Update Tags Filtered"
                              : "Update All Tags"}
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={handleDeleteAllContacts}
                            href="#"
                          >
                            Delete All
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="flex-shrink-0">
                      {/* View & Edit Modal */}
                      <div
                        className="sidemodal slide-from-right modal fade"
                        id="myModal"
                        tabIndex="-1"
                      >
                        <div className="side-modal-dialog modal-dialog modal-dialog-scrollable">
                          <div className="side-modal-content modal-content">
                            <div className="modal-header">
                              <h4 className="modal-title">{contact.name}</h4>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                              ></button>
                            </div>
                            <form onSubmit={handleSubmitLeads}>
                              <div className="modal-body scrollable-modal-body">
                                <section className="qualification section">
                                  <div className="section__title">
                                    <img
                                      width={85}
                                      height={85}
                                      style={{ borderRadius: "50%" }}
                                      src="https://confidentialcontent.s3.eu-west-1.wasabisys.com/wateam/kz734vks.png"
                                      alt=""
                                    />
                                  </div>
                                  {/* <span className="section__subtitle">Name</span> */}
                                  <div className="qualification__container container">
                                    <div className="qualification__tabs">
                                      <div
                                        className={
                                          toggleState === 1
                                            ? "qualification__button qualification__active button-flex"
                                            : "qualification__button button-flex"
                                        }
                                        onClick={() => toggleTab(1)}
                                      >
                                        <h6>General Details</h6>
                                      </div>
                                      <div
                                        className={
                                          toggleState === 2
                                            ? "qualification__button qualification__active button-flex"
                                            : "qualification__button button-flex"
                                        }
                                        onClick={() => toggleTab(2)}
                                      >
                                        <h6>Custom Details</h6>
                                      </div>
                                    </div>

                                    <div className="qualification__sections">
                                      <div
                                        className={
                                          toggleState === 1
                                            ? "qualification__content qualification__content-active"
                                            : "qualification__content"
                                        }
                                      >
                                        <div className="qualification__data">
                                          <div>
                                            <TextField
                                              color="primary"
                                              size="small"
                                              fullWidth
                                              label="Enter Name"
                                              name="name"
                                              value={contact.name}
                                              onChange={handleChangeContact}
                                              margin="normal"
                                              type="text"
                                              required
                                              autoFocus
                                            />
                                          </div>
                                          <div>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              label="Enter Email"
                                              name="email"
                                              value={contact.email}
                                              onChange={handleChangeContact}
                                              margin="normal"
                                              type="email"
                                              required
                                            />
                                          </div>
                                          <div>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              margin="normal"
                                              label="Whatsapp Number"
                                              name="whatsappMobile"
                                              value={contact.whatsappMobile}
                                              onChange={handleChangeContact}
                                              type="tel"
                                              required
                                              autoFocus
                                            />
                                          </div>
                                          <div>
                                            <TextField
                                              size="small"
                                              margin="normal"
                                              required
                                              fullWidth
                                              label="Phone"
                                              name="mobile"
                                              value={contact.mobile}
                                              onChange={handleChangeContact}
                                              type="tel"
                                              inputProps={{
                                                maxLength: 12,
                                              }}
                                              autoComplete="mobile"
                                              autoFocus
                                            />
                                          </div>
                                          <div>
                                            <TextField
                                              size="small"
                                              margin="normal"
                                              fullWidth
                                              label="Personalised Attachement"
                                              name="personalisedattachment"
                                              value={
                                                contact.personalisedattachment
                                              }
                                              onChange={handleChangeContact}
                                              autoComplete="personalisedattachment"
                                              autoFocus
                                            />
                                          </div>
                                          <div>
                                            <div>
                                              <Multiselect
                                                isObject={false}
                                                onSelect={(selectedList) =>
                                                  handleChangeTags(selectedList)
                                                }
                                                onRemove={(selectedList) =>
                                                  handleChangeTags(selectedList)
                                                }
                                                options={options}
                                                selectedValues={selectedTags} // Pass selected tags to the Multiselect component
                                                showCheckbox
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className={
                                          toggleState === 2
                                            ? "qualification__content qualification__content-active"
                                            : "qualification__content"
                                        }
                                      >
                                        <div className="qualification__data">
                                          <div>
                                            {customFields.map(
                                              (field, index) => (
                                                <div key={index}>
                                                  <Grid
                                                    container
                                                    spacing={1}
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <Grid item xs={11}>
                                                      <TextField
                                                        size="small"
                                                        fullWidth
                                                        label={`${field.title}`}
                                                        type="text"
                                                        margin="normal"
                                                        value={field.value}
                                                        onChange={(e) =>
                                                          handleCustomFieldValueChange(
                                                            index,
                                                            e
                                                          )
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                      <MinusCircleOutlined
                                                        style={{
                                                          fontSize: "25px",
                                                          textAlign: "center",
                                                        }}
                                                        onClick={() =>
                                                          handleRemoveCustomField(
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </div>
                                              )
                                            )}

                                            <Grid
                                              container
                                              spacing={1}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginTop: "10px",
                                                marginBottom: "20px",
                                              }}
                                            >
                                              <Grid item xs={9}>
                                                <select
                                                  className="form-select"
                                                  margin="normal"
                                                  value={nextCustomFieldType}
                                                  onChange={(e) =>
                                                    setNextCustomFieldType(
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option value="">
                                                    Select a Custom Field
                                                  </option>
                                                  {customsData.map((custom) => (
                                                    <option
                                                      key={custom._id}
                                                      value={custom.title}
                                                    >
                                                      {custom.title}
                                                    </option>
                                                  ))}
                                                </select>
                                              </Grid>
                                              <Grid item xs={2}>
                                                <Button
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                  type="dashed"
                                                  onClick={handleAddCustomField}
                                                  icon={<PlusOutlined />}
                                                >
                                                  Add field
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>

                              {/*<!-- Modal footer --> */}
                              <div className="modal-footer fixed-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  data-bs-dismiss="modal"
                                  className="btn btn-primary"
                                >
                                  {editContactId ? "Update Leads" : "Add Leads"}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* Filter Modal */}
                      <div
                        className="sidemodal slide-from-left modal fade"
                        id="myLeftModal"
                        tabIndex="-1"
                      >
                        <div className="side-modal-dialog modal-dialog modal-dialog-scrollable">
                          <div className="side-modal-content modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">Contact Filters</h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                              ></button>
                            </div>

                            <div className="modal-body">
                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="w-100">
                                  <h6 style={{ float: "left" }}>
                                    Select Field
                                  </h6>

                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="field"
                                    value={selectedField}
                                    onChange={(e) =>
                                      setSelectedField(e.target.value)
                                    }
                                  >
                                    <option value="" disabled defaultValue>
                                      --Select--
                                    </option>
                                    <optgroup label="CUSTOM FIELDS">
                                      {customTitle.map((title, index) => (
                                        <option key={index} value={title}>
                                          {title}
                                        </option>
                                      ))}
                                    </optgroup>
                                  </select>
                                </div>
                                <div className="w-100">
                                  <h6 style={{ float: "left" }}>
                                    Select Condition
                                  </h6>
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="condition"
                                    value={selectedCondition}
                                    onChange={(e) =>
                                      setSelectedCondition(e.target.value)
                                    }
                                  >
                                    <option value="" disabled defaultValue>
                                      --Select--
                                    </option>
                                    <optgroup label="GENERAL CONDITIONS">
                                      <option value="exist" name="exist">
                                        exist
                                      </option>
                                      <option
                                        value="doesnotexist"
                                        name="doesnotexist"
                                      >
                                        does not exist
                                      </option>
                                    </optgroup>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer fixed-footer">
                              <button
                                type="submit"
                                data-bs-dismiss="modal"
                                className="btn btn-primary w-50"
                                onClick={() => applyFilter()}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Edit All Modal */}
                      <div
                        className="sidemodal slide-from-left modal fade"
                        id="myEditModal"
                        tabIndex="-1"
                      >
                        <div className="side-modal-dialog modal-dialog modal-dialog-scrollable">
                          <div className="side-modal-content modal-content">
                            <div className="modal-header">
                              {/* <h5 className="modal-title">Edit In Bulk</h5> */}
                              <h5 className="modal-title">
                                {isEditingSelected
                                  ? "Edit Selected In Bulk"
                                  : filtersActive
                                  ? "Edit Filtered In Bulk"
                                  : "Edit All In Bulk"}
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                              ></button>
                            </div>

                            <div className="modal-body">
                              <div>
                                {isEditingSelected
                                  ? editSelectedValue.map((field, index) => (
                                      // Render form fields for selected contacts"
                                      <div key={index}>
                                        <Grid
                                          container
                                          spacing={1}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Grid item xs={11}>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              label={`${field.title}`}
                                              type="text"
                                              margin="normal"
                                              value={field.value}
                                              onChange={(e) =>
                                                handleEditSelectedValueChange(
                                                  index,
                                                  e
                                                )
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={1}>
                                            <MinusCircleOutlined
                                              style={{
                                                fontSize: "25px",
                                                textAlign: "center",
                                              }}
                                              onClick={() =>
                                                handleRemoveEditSelected(index)
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    ))
                                  : filtersActive
                                  ? editFilteredValue.map((field, index) => (
                                      // Render form fields for all contacts
                                      <div key={index}>
                                        <Grid
                                          container
                                          spacing={1}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Grid item xs={11}>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              label={`${field.title}`}
                                              type="text"
                                              margin="normal"
                                              value={field.value}
                                              onChange={(e) =>
                                                handleEditFilteredValueChange(
                                                  index,
                                                  e
                                                )
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={1}>
                                            <MinusCircleOutlined
                                              style={{
                                                fontSize: "25px",
                                                textAlign: "center",
                                              }}
                                              onClick={() =>
                                                handleRemoveEditFiltered(index)
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    ))
                                  : editAllValue.map((field, index) => (
                                      // Render form fields for all contacts
                                      <div key={index}>
                                        <Grid
                                          container
                                          spacing={1}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Grid item xs={11}>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              label={`${field.title}`}
                                              type="text"
                                              margin="normal"
                                              value={field.value}
                                              onChange={(e) =>
                                                handleEditAllValueChange(
                                                  index,
                                                  e
                                                )
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={1}>
                                            <MinusCircleOutlined
                                              style={{
                                                fontSize: "25px",
                                                textAlign: "center",
                                              }}
                                              onClick={() =>
                                                handleRemoveEditAll(index)
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    ))}

                                {isEditingSelected ? (
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "10px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Grid item xs={9}>
                                      <select
                                        className="form-select"
                                        margin="normal"
                                        value={nextEditSelected}
                                        onChange={(e) =>
                                          setNextEditSelected(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select a Custom Field
                                        </option>
                                        {customsData.map((custom) => (
                                          <option
                                            key={custom._id}
                                            value={custom.title}
                                          >
                                            {custom.title}
                                          </option>
                                        ))}
                                      </select>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Button
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        type="dashed"
                                        onClick={handleAddEditSelected}
                                        icon={<PlusOutlined />}
                                      ></Button>
                                    </Grid>
                                  </Grid>
                                ) : filtersActive ? (
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "10px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Grid item xs={9}>
                                      <select
                                        className="form-select"
                                        margin="normal"
                                        value={nextEditFiltered}
                                        onChange={(e) =>
                                          setNextEditFiltered(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select a Custom Field
                                        </option>
                                        {customsData.map((custom) => (
                                          <option
                                            key={custom._id}
                                            value={custom.title}
                                          >
                                            {custom.title}
                                          </option>
                                        ))}
                                      </select>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Button
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        type="dashed"
                                        onClick={handleAddEditFiltered}
                                        icon={<PlusOutlined />}
                                      ></Button>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    container
                                    spacing={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "10px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <Grid item xs={9}>
                                      <select
                                        className="form-select"
                                        margin="normal"
                                        value={nextEditAll}
                                        onChange={(e) =>
                                          setNextEditAll(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select a Custom Field
                                        </option>
                                        {customsData.map((custom) => (
                                          <option
                                            key={custom._id}
                                            value={custom.title}
                                          >
                                            {custom.title}
                                          </option>
                                        ))}
                                      </select>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Button
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        type="dashed"
                                        onClick={handleAddEditAll}
                                        icon={<PlusOutlined />}
                                      ></Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </div>
                            </div>
                            <div className="modal-footer fixed-footer">
                              <button
                                type="submit"
                                data-bs-dismiss="modal"
                                className="btn btn-primary w-50"
                                onClick={
                                  isEditingSelected
                                    ? applyEditSelectedChanges
                                    : filtersActive
                                    ? applyEditFilteredChanges
                                    : applyEditAllChanges
                                }
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2 align-item-center"></div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <Table
                    dataSource={getDataSource()}
                    columns={columns}
                    rowKey="_id"
                    rowSelection={rowSelection}
                    /*                     pagination={{
                      pageSize: tableParams.pagination.pageSize,
                      position: "bottomRight",
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "15", "20", "50", "100"],
                    }} */

                    pagination={{
                      ...tableParams.pagination,
                      total,
                      position: "bottomRight",
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "15", "20", "50", "100"],
                    }}
                    onChange={handleTableChange}
                    // scroll={{ y: 800 }}
                    bordered
                    components={{
                      header: {
                        cell: ({ style, ...restProps }) => (
                          <th
                            {...restProps}
                            style={{
                              ...style,
                              backgroundColor: "#c6c6c6",
                              color: "black",
                              textAlign: "center",
                            }}
                          />
                        ),
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedContactId && <Status contactId={selectedContactId} />}
    </div>
  );
};

export default Leads;
